<template>
  <div>
    <a-tabs default-active-key="1" :tab-position="'left'">
      <a-tab-pane key="1" force-render>
        <template slot="tab">
          <a-icon class="f20" type="idcard" />
        </template>
        <a-row id="contract-files" :gutter="20">
          <a-col :span="24">
            <a-row :gutter="20">
              <a-col class="mb-20" :span="24">
                <div class="label mb-10">Frente do Documento</div>
                <div v-if="!tempContract['contract_files_doc_front']">
                  <a-upload
                    name="file"
                    :multiple="false"
                    :action="`https://api.viajar.tur.br/v1/media/upload-to-s3?module=contracts&id=${tempContract.id}&sub-folder=doc-front`"
                    :remove="removeUploadedFile"
                    :headers="{
                      authorization: `Bearer ${this.$store.state.token}`,
                    }"
                    @change="uploadedFile"
                    v-model="tempContract['contract_files_doc_front']"
                  >
                    <a-button
                      v-if="tempContract['contract_files_doc_front'] == ''"
                      type="dashed"
                      shape="circle"
                      size="large"
                    >
                      <a-icon type="idcard" />
                    </a-button>
                  </a-upload>
                </div>

                <div
                  v-if="tempContract['contract_files_doc_front']"
                  class="file-link relative"
                >
                  <a
                    v-if="tempContract['contract_files_doc_front']"
                    :href="tempContract['contract_files_doc_front']"
                    target="_blank"
                  >
                    <img
                      v-if="
                        checkFileType(tempContract['contract_files_doc_front'])
                      "
                      :src="tempContract['contract_files_doc_front']"
                      style="max-width: 260px"
                    />
                    <a-icon
                      v-else
                      class="cblue"
                      type="idcard"
                      style="font-size: 38px"
                    />
                  </a>

                  <a-icon
                    class="close-circle"
                    type="close-circle"
                    v-if="tempContract['contract_files_doc_front']"
                    @click="removefile('doc-front')"
                  />
                </div>
              </a-col>

              <a-col :span="12">
                <a-form-item>
                  <div class="label">Data de criação</div>
                  <a-date-picker
                    class="travel-input"
                    placeholder="Selecione"
                    format="DD/MM/YYYY HH:mm"
                    :showToday="false"
                    :allowClear="false"
                    :disabled="true"
                    v-mask="'##/##/####'"
                    v-decorator="[
                      'contract_files_doc_front_created',
                      {
                        rules: [
                          {
                            required: false,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-date-picker>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item>
                  <div class="label">Data de validade</div>

                  <a-date-picker
                    class="travel-input"
                    placeholder="Selecione"
                    format="DD/MM/YYYY"
                    :showToday="false"
                    :allowClear="true"
                    :disabled-date="minDate"
                    v-mask="'##/##/####'"
                    v-decorator="[
                      'contract_files_doc_front_expires_at',
                      {
                        rules: [
                          {
                            required: false,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-date-picker>
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>

          <a-col :span="24">
            <a-row :gutter="20">
              <a-col class="mb-20" :span="24">
                <div class="label mb-10">Verso do Documento</div>
                <div v-if="!tempContract['contract_files_doc_back']">
                  <a-upload
                    name="file"
                    :multiple="false"
                    :action="`https://api.viajar.tur.br/v1/media/upload-to-s3?module=contracts&id=${tempContract.id}&sub-folder=doc-back`"
                    :remove="removeUploadedFile"
                    :headers="{
                      authorization: `Bearer ${this.$store.state.token}`,
                    }"
                    @change="uploadedFile"
                    v-model="tempContract['contract_files_doc_back']"
                  >
                    <a-button
                      v-if="tempContract['contract_files_doc_back'] == ''"
                      type="dashed"
                      shape="circle"
                      size="large"
                    >
                      <a-icon type="idcard" />
                    </a-button>
                  </a-upload>
                </div>

                <div
                  v-if="tempContract['contract_files_doc_back']"
                  class="file-link relative"
                >
                  <a
                    v-if="tempContract['contract_files_doc_back']"
                    :href="tempContract['contract_files_doc_back']"
                    target="_blank"
                  >
                    <img
                      v-if="
                        checkFileType(tempContract['contract_files_doc_back'])
                      "
                      :src="tempContract['contract_files_doc_back']"
                      style="max-width: 260px"
                    />
                    <a-icon
                      v-else
                      class="cblue"
                      type="idcard"
                      style="font-size: 38px"
                    />
                  </a>

                  <a-icon
                    class="close-circle"
                    type="close-circle"
                    v-if="tempContract['contract_files_doc_back']"
                    @click="removefile('doc-back')"
                  />
                </div>
              </a-col>

              <a-col :span="12">
                <a-form-item>
                  <div class="label">Data de criação</div>
                  <a-date-picker
                    class="travel-input"
                    placeholder="Selecione"
                    format="DD/MM/YYYY HH:mm"
                    :showToday="false"
                    :allowClear="false"
                    :disabled="true"
                    v-mask="'##/##/####'"
                    v-decorator="[
                      'contract_files_doc_back_created',
                      {
                        rules: [
                          {
                            required: false,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-date-picker>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item>
                  <div class="label">Data de validade</div>

                  <a-date-picker
                    class="travel-input"
                    placeholder="Selecione"
                    format="DD/MM/YYYY"
                    :showToday="false"
                    :allowClear="true"
                    :disabled-date="minDate"
                    v-mask="'##/##/####'"
                    v-decorator="[
                      'contract_files_doc_back_expires_at',
                      {
                        rules: [
                          {
                            required: false,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-date-picker>
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-tab-pane>

      <a-tab-pane key="2" force-render>
        <template slot="tab">
          <a-icon class="f20" type="credit-card" />
        </template>
        <a-row id="contract-files" :gutter="20">
          <a-col :span="24">
            <a-row :gutter="20">
              <a-col class="mb-20" :span="24">
                <div class="label mb-10">Frente do Cartão</div>
                <div v-if="!tempContract['contract_files_credit_card_front']">
                  <a-upload
                    name="file"
                    :multiple="false"
                    :action="`https://api.viajar.tur.br/v1/media/upload-to-s3?module=contracts&id=${tempContract.id}&sub-folder=credit-card-front`"
                    :remove="removeUploadedFile"
                    :headers="{
                      authorization: `Bearer ${this.$store.state.token}`,
                    }"
                    @change="uploadedFile"
                    v-model="tempContract['contract_files_credit_card_front']"
                  >
                    <a-button
                      v-if="
                        tempContract['contract_files_credit_card_front'] == ''
                      "
                      type="dashed"
                      shape="circle"
                      size="large"
                    >
                      <a-icon type="credit-card" />
                    </a-button>
                  </a-upload>
                </div>

                <div
                  v-if="tempContract['contract_files_credit_card_front']"
                  class="file-link relative"
                >
                  <a
                    v-if="tempContract['contract_files_credit_card_front']"
                    :href="tempContract['contract_files_credit_card_front']"
                    target="_blank"
                  >
                    <img
                      v-if="
                        checkFileType(
                          tempContract['contract_files_credit_card_front']
                        )
                      "
                      :src="tempContract['contract_files_credit_card_front']"
                      style="max-width: 260px"
                    />
                    <a-icon
                      v-else
                      class="cblue"
                      type="credit-card"
                      style="font-size: 38px"
                    />
                  </a>

                  <a-icon
                    class="close-circle"
                    type="close-circle"
                    v-if="tempContract['contract_files_credit_card_front']"
                    @click="removefile('credit-card-front')"
                  />
                </div>
              </a-col>

              <a-col :span="12">
                <a-form-item>
                  <div class="label">Data de criação</div>
                  <a-date-picker
                    class="travel-input"
                    placeholder="Selecione"
                    format="DD/MM/YYYY HH:mm"
                    :showToday="false"
                    :allowClear="false"
                    :disabled="true"
                    v-mask="'##/##/####'"
                    v-decorator="[
                      'contract_files_credit_card_front_created',
                      {
                        rules: [
                          {
                            required: false,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-date-picker>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item>
                  <div class="label">Data de validade</div>

                  <a-date-picker
                    class="travel-input"
                    placeholder="Selecione"
                    format="DD/MM/YYYY"
                    :showToday="false"
                    :allowClear="true"
                    :disabled-date="minDate"
                    v-mask="'##/##/####'"
                    v-decorator="[
                      'contract_files_credit_card_front_expires_at',
                      {
                        rules: [
                          {
                            required: false,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-date-picker>
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>

          <a-col :span="24">
            <a-row :gutter="20">
              <a-col class="mb-20" :span="24">
                <div class="label mb-10">Verso do Cartão</div>
                <div v-if="!tempContract['contract_files_credit_card_back']">
                  <a-upload
                    :multiple="false"
                    :action="`https://api.viajar.tur.br/v1/media/upload-to-s3?module=contracts&id=${tempContract.id}&sub-folder=credit-card-back`"
                    :remove="removeUploadedFile"
                    :headers="{
                      authorization: `Bearer ${this.$store.state.token}`,
                    }"
                    @change="uploadedFile"
                    v-model="tempContract['contract_files_credit_card_back']"
                  >
                    <a-button
                      v-if="
                        tempContract['contract_files_credit_card_back'] == ''
                      "
                      type="dashed"
                      shape="circle"
                      size="large"
                    >
                      <a-icon type="credit-card" />
                    </a-button>
                  </a-upload>
                </div>

                <div
                  v-if="tempContract['contract_files_credit_card_back']"
                  class="file-link relative"
                >
                  <a
                    v-if="tempContract['contract_files_credit_card_back']"
                    :href="tempContract['contract_files_credit_card_back']"
                    target="_blank"
                  >
                    <img
                      v-if="
                        checkFileType(
                          tempContract['contract_files_credit_card_back']
                        )
                      "
                      :src="tempContract['contract_files_credit_card_back']"
                      style="max-width: 260px"
                    />
                    <a-icon
                      v-else
                      class="cblue"
                      type="credit-card"
                      style="font-size: 38px"
                    />
                  </a>

                  <a-icon
                    class="close-circle"
                    type="close-circle"
                    v-if="tempContract['contract_files_credit_card_back']"
                    @click="removefile('credit-card-back')"
                  />
                </div>
              </a-col>

              <a-col :span="12">
                <a-form-item>
                  <div class="label">Data de criação</div>
                  <a-date-picker
                    class="travel-input"
                    placeholder="Selecione"
                    format="DD/MM/YYYY HH:mm"
                    :showToday="false"
                    :allowClear="false"
                    :disabled="true"
                    v-mask="'##/##/####'"
                    v-decorator="[
                      'contract_files_credit_card_back_created',
                      {
                        rules: [
                          {
                            required: false,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-date-picker>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item>
                  <div class="label">Data de validade</div>

                  <a-date-picker
                    class="travel-input"
                    placeholder="Selecione"
                    format="DD/MM/YYYY"
                    :showToday="false"
                    :allowClear="true"
                    :disabled-date="minDate"
                    v-mask="'##/##/####'"
                    v-decorator="[
                      'contract_files_credit_card_back_expires_at',
                      {
                        rules: [
                          {
                            required: false,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-date-picker>
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>

          <a-col :span="24">
            <a-row :gutter="20">
              <a-col class="mb-20" :span="24">
                <div class="label mb-10">Slip de pagamento</div>
                <div v-if="!tempContract['contract_files_credit_card_slip']">
                  <a-upload
                    :multiple="false"
                    :action="`https://api.viajar.tur.br/v1/media/upload-to-s3?module=contracts&id=${tempContract.id}&sub-folder=credit-card-slip`"
                    :remove="removeUploadedFile"
                    :headers="{
                      authorization: `Bearer ${this.$store.state.token}`,
                    }"
                    @change="uploadedFile"
                    v-model="tempContract['contract_files_credit_card_slip']"
                  >
                    <a-button
                      v-if="
                        tempContract['contract_files_credit_card_slip'] == ''
                      "
                      type="dashed"
                      shape="circle"
                      size="large"
                    >
                      <a-icon type="profile" />
                    </a-button>
                  </a-upload>
                </div>

                <div
                  v-if="tempContract['contract_files_credit_card_slip']"
                  class="file-link relative"
                >
                  <a
                    v-if="tempContract['contract_files_credit_card_slip']"
                    :href="tempContract['contract_files_credit_card_slip']"
                    target="_blank"
                  >
                    <img
                      v-if="
                        checkFileType(
                          tempContract['contract_files_credit_card_slip']
                        )
                      "
                      :src="tempContract['contract_files_credit_card_slip']"
                      style="max-width: 260px"
                    />
                    <a-icon
                      v-else
                      class="cblue"
                      type="profile"
                      style="font-size: 38px"
                    />
                  </a>

                  <a-icon
                    class="close-circle"
                    type="close-circle"
                    v-if="tempContract['contract_files_credit_card_slip']"
                    @click="removefile('credit-card-slip')"
                  />
                </div>
              </a-col>

              <a-col :span="12">
                <a-form-item>
                  <div class="label">Data de criação</div>
                  <a-date-picker
                    class="travel-input"
                    placeholder="Selecione"
                    format="DD/MM/YYYY HH:mm"
                    :showToday="false"
                    :allowClear="false"
                    :disabled="true"
                    v-mask="'##/##/####'"
                    v-decorator="[
                      'contract_files_credit_card_slip_created',
                      {
                        rules: [
                          {
                            required: false,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-date-picker>
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-tab-pane>

      <a-tab-pane key="3" force-render>
        <template slot="tab">
          <a-icon class="f20" type="file-protect" />
        </template>
        <a-row id="contract-files" :gutter="20">
          <a-col :span="24">
            <a-row :gutter="20">
              <a-col class="mb-20" :span="24">
                <div class="label mb-10">Contrato Assinado</div>
                <div v-if="!tempContract['contract_files_signed_contract']">
                  <a-upload
                    :multiple="false"
                    :action="`https://api.viajar.tur.br/v1/media/upload-to-s3?module=contracts&id=${tempContract.id}&sub-folder=signed-contract`"
                    :remove="removeUploadedFile"
                    :headers="{
                      authorization: `Bearer ${this.$store.state.token}`,
                    }"
                    @change="uploadedFile"
                    v-model="tempContract['contract_files_signed_contract']"
                  >
                    <a-button
                      v-if="
                        tempContract['contract_files_signed_contract'] == ''
                      "
                      type="dashed"
                      shape="circle"
                      size="large"
                    >
                      <a-icon type="file-protect" />
                    </a-button>
                  </a-upload>
                </div>

                <div
                  v-if="tempContract['contract_files_signed_contract']"
                  class="file-link relative"
                >
                  <a
                    v-if="tempContract['contract_files_signed_contract']"
                    :href="tempContract['contract_files_signed_contract']"
                    target="_blank"
                  >
                    <img
                      v-if="
                        checkFileType(
                          tempContract['contract_files_signed_contract']
                        )
                      "
                      :src="tempContract['contract_files_signed_contract']"
                      style="max-width: 260px"
                    />
                    <a-icon
                      v-else
                      class="cblue"
                      type="file-protect"
                      style="font-size: 38px"
                    />
                  </a>

                  <a-icon
                    class="close-circle"
                    type="close-circle"
                    v-if="tempContract['contract_files_signed_contract']"
                    @click="removefile('signed-contract')"
                  />
                </div>
              </a-col>

              <a-col :span="12">
                <a-form-item>
                  <div class="label">Data de criação</div>
                  <a-date-picker
                    class="travel-input"
                    placeholder="Selecione"
                    format="DD/MM/YYYY HH:mm"
                    :showToday="false"
                    :allowClear="false"
                    :disabled="true"
                    v-mask="'##/##/####'"
                    v-decorator="[
                      'contract_files_signed_contract_created',
                      {
                        rules: [
                          {
                            required: false,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-date-picker>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item>
                  <div class="label">Data de validade</div>

                  <a-date-picker
                    class="travel-input"
                    placeholder="Selecione"
                    format="DD/MM/YYYY"
                    :showToday="false"
                    :allowClear="true"
                    :disabled-date="minDate"
                    v-mask="'##/##/####'"
                    v-decorator="[
                      'contract_files_signed_contract_expires_at',
                      {
                        rules: [
                          {
                            required: false,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-date-picker>
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-tab-pane>

      <a-tab-pane key="4" force-render>
        <template slot="tab">
          <a-icon class="f20" type="folder-open" />
        </template>
        <a-row id="contract-files" :gutter="20">
          <a-col :span="24">
            <a-row :gutter="20">
              <a-col class="mb-20" :span="24">
                <div class="label mb-10">Outros arquivos</div>
                <div v-if="!tempContract['contract_files_other_files']">
                  <a-upload
                    :multiple="false"
                    :action="`https://api.viajar.tur.br/v1/media/upload-to-s3?module=contracts&id=${tempContract.id}&sub-folder=other-files`"
                    :remove="removeUploadedFile"
                    :headers="{
                      authorization: `Bearer ${this.$store.state.token}`,
                    }"
                    @change="uploadedFile"
                    v-model="tempContract['contract_files_other_files']"
                  >
                    <a-button
                      v-if="tempContract['contract_files_other_files'] == ''"
                      type="dashed"
                      shape="circle"
                      size="large"
                    >
                      <a-icon type="folder" />
                    </a-button>
                  </a-upload>
                </div>

                <div
                  v-if="tempContract['contract_files_other_files']"
                  class="file-link relative"
                >
                  <a
                    v-if="tempContract['contract_files_other_files']"
                    :href="tempContract['contract_files_other_files']"
                    target="_blank"
                  >
                    <img
                      v-if="
                        checkFileType(
                          tempContract['contract_files_other_files']
                        )
                      "
                      :src="tempContract['contract_files_other_files']"
                      style="max-width: 260px"
                    />
                    <a-icon
                      v-else
                      class="cblue"
                      type="folder"
                      style="font-size: 38px"
                    />
                  </a>

                  <a-icon
                    class="close-circle"
                    type="close-circle"
                    v-if="tempContract['contract_files_other_files']"
                    @click="removefile('other-files')"
                  />
                </div>
              </a-col>

              <a-col class="none" :span="12">
                <a-form-item>
                  <div class="label">Data de criação</div>
                  <a-date-picker
                    class="travel-input"
                    placeholder="Selecione"
                    format="DD/MM/YYYY HH:mm"
                    :showToday="false"
                    :allowClear="false"
                    :disabled="true"
                    v-mask="'##/##/####'"
                    v-decorator="[
                      'contract_files_other_files_created',
                      {
                        rules: [
                          {
                            required: false,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-date-picker>
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
export default {
  name: "ContractFilesModal",
  props: {
    contract: Object,
    tempContract: Object,
    form: Object,
  },
  data() {
    return {
      contract_files_credit_card_front: "",
    };
  },
  beforeMount() {
    if (!this.tempContract["contract_files_credit_card_front"]) {
      this.tempContract["contract_files_credit_card_front"] = "";
    }

    if (!this.tempContract["contract_files_other_files"]) {
      this.tempContract["contract_files_other_files"] = "";
    }

    if (!this.tempContract["contract_files_credit_card_back"]) {
      this.tempContract["contract_files_credit_card_back"] = "";
    }

    if (!this.tempContract["contract_files_credit_card_slip"]) {
      this.tempContract["contract_files_credit_card_slip"] = "";
    }

    if (!this.tempContract["contract_files_doc_front"]) {
      this.tempContract["contract_files_doc_front"] = "";
    }

    if (!this.tempContract["contract_files_doc_back"]) {
      this.tempContract["contract_files_doc_back"] = "";
    }

    if (!this.tempContract["contract_files_signed_contract"]) {
      this.tempContract["contract_files_signed_contract"] = "";
    }
  },
  mounted() {
    this.form.setFieldsValue({
      [`contract_files_credit_card_front`]:
        this.tempContract["contract_files_credit_card_front"],
      [`contract_files_credit_card_front_created`]:
        this.tempContract["contract_files_credit_card_front_created"],
      [`contract_files_credit_card_front_expires_at`]:
        this.tempContract["contract_files_credit_card_front_expires_at"],
      [`contract_files_credit_card_back`]:
        this.tempContract["contract_files_credit_card_back"],
      [`contract_files_credit_card_slip`]:
        this.tempContract["contract_files_credit_card_slip"],
      [`contract_files_credit_card_slip_created`]:
        this.tempContract["contract_files_credit_card_slip_created"],
      [`contract_files_credit_card_back_created`]:
        this.tempContract["contract_files_credit_card_back_created"],
      [`contract_files_credit_card_back_expires_at`]:
        this.tempContract["contract_files_credit_card_back_expires_at"],
      [`contract_files_doc_front`]:
        this.tempContract["contract_files_doc_front"],
      [`contract_files_doc_front_created`]:
        this.tempContract["contract_files_doc_front_created"],
      [`contract_files_doc_front_expires_at`]:
        this.tempContract["contract_files_doc_front_expires_at"],
      [`contract_files_doc_back`]: this.tempContract["contract_files_doc_back"],
      [`contract_files_doc_back_created`]:
        this.tempContract["contract_files_doc_back_created"],
      [`contract_files_doc_back_expires_at`]:
        this.tempContract["contract_files_doc_back_expires_at"],
      [`contract_files_signed_contract`]:
        this.tempContract["contract_files_signed_contract"],
      [`contract_files_signed_contract_created`]:
        this.tempContract["contract_files_signed_contract_created"],
      [`contract_files_signed_contract_expires_at`]:
        this.tempContract["contract_files_signed_contract_expires_at"],
    });
  },
  methods: {
    checkFileType(fileUrl) {
      let flag = false;
      let filePieces = fileUrl.split(".");
      // console.log(fileUrl, filePieces, filePieces.length);
      if (
        filePieces[filePieces.length - 1] == "jpg" ||
        filePieces[filePieces.length - 1] == "png"
      ) {
        flag = true;
      }

      return flag;
    },
    removefile(fileName) {
      //console.log(fileName);
      if (fileName == "credit-card-front") {
        this.form.setFieldsValue({
          [`contract_files_credit_card_front`]: "",
          [`contract_files_credit_card_front_created`]: "",
          [`contract_files_credit_card_front_expires_at`]: "",
        });
      }

      if (fileName == "credit-card-back") {
        this.form.setFieldsValue({
          [`contract_files_credit_card_back`]: "",
          [`contract_files_credit_card_back_created`]: "",
          [`contract_files_credit_card_back_expires_at`]: "",
        });
      }

      if (fileName == "doc-front") {
        this.form.setFieldsValue({
          [`contract_files_doc_front`]: "",
          [`contract_files_doc_front_created`]: "",
          [`contract_files_doc_front_expires_at`]: "",
        });
      }

      if (fileName == "doc-back") {
        this.form.setFieldsValue({
          [`contract_files_doc_back`]: "",
          [`contract_files_doc_back_created`]: "",
          [`contract_files_doc_back_expires_at`]: "",
        });
      }

      if (fileName == "signed-contract") {
        this.form.setFieldsValue({
          [`contract_files_signed_contract`]: "",
          [`contract_files_signed_contract_created`]: "",
          [`contract_files_signed_contract_expires_at`]: "",
        });
      }

      if (fileName == "credit-card-slip") {
        this.form.setFieldsValue({
          [`contract_files_credit_card_slip`]: "",
          [`contract_files_credit_card_slip_created`]: "",
        });
      }
    },
    removeUploadedFile(file) {
      //console.log("remove", file, file.url, file.response.url);
      file;
    },
    uploadedFile(response, e) {
      e;
      /// console.log("uploadedFile", response, e);

      if (response.file.response != undefined) {
        if (response.file.response.url != undefined) {
          let urlPieces = response.file.response.url.split("/");

          // console.log("uploadedFile", urlPieces[5]);

          if (urlPieces[5] == "credit-card-front") {
            this.tempContract["contract_files_credit_card_front"] =
              response.file.response.url;
            this.tempContract["contract_files_credit_card_front_created"] =
              response.file.response.created;
            setTimeout(() => {
              this.form.setFieldsValue({
                [`contract_files_credit_card_front`]:
                  response.file.response.url,
                [`contract_files_credit_card_front_created`]:
                  response.file.response.created,
              });
            }, 100);
          }

          if (urlPieces[5] == "credit-card-slip") {
            this.tempContract["contract_files_credit_card_slip"] =
              response.file.response.url;
            this.tempContract["contract_files_credit_card_slip_created"] =
              response.file.response.created;
            setTimeout(() => {
              this.form.setFieldsValue({
                [`contract_files_credit_card_slip`]: response.file.response.url,
                [`contract_files_credit_card_slip_created`]:
                  response.file.response.created,
              });
            }, 100);
          }

          if (urlPieces[5] == "other-files") {
            this.tempContract["contract_files_other_files"] =
              response.file.response.url;
            this.tempContract["contract_files_other_files_created"] =
              response.file.response.created;
            setTimeout(() => {
              this.form.setFieldsValue({
                [`contract_files_other_files`]: response.file.response.url,
                [`contract_files_other_files_created`]:
                  response.file.response.created,
              });
            }, 100);
          }

          if (urlPieces[5] == "credit-card-back") {
            this.tempContract["contract_files_credit_card_back"] =
              response.file.response.url;
            this.tempContract["contract_files_credit_card_back_created"] =
              response.file.response.created;
            setTimeout(() => {
              this.form.setFieldsValue({
                [`contract_files_credit_card_back`]: response.file.response.url,
                [`contract_files_credit_card_back_created`]:
                  response.file.response.created,
              });
            }, 100);
          }

          if (urlPieces[5] == "doc-front") {
            this.tempContract["contract_files_doc_front"] =
              response.file.response.url;
            this.tempContract["contract_files_doc_front_created"] =
              response.file.response.created;
            setTimeout(() => {
              this.form.setFieldsValue({
                [`contract_files_doc_front`]: response.file.response.url,
                [`contract_files_doc_front_created`]:
                  response.file.response.created,
              });
            }, 100);
          }

          if (urlPieces[5] == "doc-back") {
            this.tempContract["contract_files_doc_back"] =
              response.file.response.url;
            this.tempContract["contract_files_doc_back_created"] =
              response.file.response.created;
            setTimeout(() => {
              this.form.setFieldsValue({
                [`contract_files_doc_back`]: response.file.response.url,
                [`contract_files_doc_back_created`]:
                  response.file.response.created,
              });
            }, 100);
          }

          if (urlPieces[5] == "signed-contract") {
            this.tempContract["contract_files_signed_contract"] =
              response.file.response.url;
            this.tempContract["contract_files_signed_contract_created"] =
              response.file.response.created;
            setTimeout(() => {
              this.form.setFieldsValue({
                [`contract_files_signed_contract`]: response.file.response.url,
                [`contract_files_signed_contract_created`]:
                  response.file.response.created,
              });
            }, 100);
          }
        }
      }
    },
    minDate(current) {
      return current && current < new Date();
    },
  },
};
</script>

<style lang="sass" scoped>
#contract-files
  .file-link
    display: inline-block
    .close-circle
      font-size: 19px
      color: red
      position: absolute
      right: -11px
      top: 0px
      border-radius: 10px
      background: #FFF
      cursor: pointer
  .label
    z-index: 4
    font-size: 10px
    font-weight: 500
    color: #aaa
    transition: .3s
    line-height: 1
    overflow: hidden
    text-overflow: ellipsis
    width: 100%
    white-space: nowrap
</style>
