var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-tabs',{attrs:{"default-active-key":"1","tab-position":'left'}},[_c('a-tab-pane',{key:"1",attrs:{"force-render":""}},[_c('template',{slot:"tab"},[_c('a-icon',{staticClass:"f20",attrs:{"type":"idcard"}})],1),_vm._v(" "),_c('a-row',{attrs:{"id":"contract-files","gutter":20}},[_c('a-col',{attrs:{"span":24}},[_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{staticClass:"mb-20",attrs:{"span":24}},[_c('div',{staticClass:"label mb-10"},[_vm._v("Frente do Documento")]),_vm._v(" "),(!_vm.tempContract['contract_files_doc_front'])?_c('div',[_c('a-upload',{attrs:{"name":"file","multiple":false,"action":`https://api.viajar.tur.br/v1/media/upload-to-s3?module=contracts&id=${_vm.tempContract.id}&sub-folder=doc-front`,"remove":_vm.removeUploadedFile,"headers":{
                    authorization: `Bearer ${this.$store.state.token}`,
                  }},on:{"change":_vm.uploadedFile},model:{value:(_vm.tempContract['contract_files_doc_front']),callback:function ($$v) {_vm.$set(_vm.tempContract, 'contract_files_doc_front', $$v)},expression:"tempContract['contract_files_doc_front']"}},[(_vm.tempContract['contract_files_doc_front'] == '')?_c('a-button',{attrs:{"type":"dashed","shape":"circle","size":"large"}},[_c('a-icon',{attrs:{"type":"idcard"}})],1):_vm._e()],1)],1):_vm._e(),_vm._v(" "),(_vm.tempContract['contract_files_doc_front'])?_c('div',{staticClass:"file-link relative"},[(_vm.tempContract['contract_files_doc_front'])?_c('a',{attrs:{"href":_vm.tempContract['contract_files_doc_front'],"target":"_blank"}},[(
                      _vm.checkFileType(_vm.tempContract['contract_files_doc_front'])
                    )?_c('img',{staticStyle:{"max-width":"260px"},attrs:{"src":_vm.tempContract['contract_files_doc_front']}}):_c('a-icon',{staticClass:"cblue",staticStyle:{"font-size":"38px"},attrs:{"type":"idcard"}})],1):_vm._e(),_vm._v(" "),(_vm.tempContract['contract_files_doc_front'])?_c('a-icon',{staticClass:"close-circle",attrs:{"type":"close-circle"},on:{"click":function($event){return _vm.removefile('doc-front')}}}):_vm._e()],1):_vm._e()]),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',[_c('div',{staticClass:"label"},[_vm._v("Data de criação")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
                    'contract_files_doc_front_created',
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'contract_files_doc_front_created',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":"Selecione","format":"DD/MM/YYYY HH:mm","showToday":false,"allowClear":false,"disabled":true}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',[_c('div',{staticClass:"label"},[_vm._v("Data de validade")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
                    'contract_files_doc_front_expires_at',
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'contract_files_doc_front_expires_at',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":"Selecione","format":"DD/MM/YYYY","showToday":false,"allowClear":true,"disabled-date":_vm.minDate}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":24}},[_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{staticClass:"mb-20",attrs:{"span":24}},[_c('div',{staticClass:"label mb-10"},[_vm._v("Verso do Documento")]),_vm._v(" "),(!_vm.tempContract['contract_files_doc_back'])?_c('div',[_c('a-upload',{attrs:{"name":"file","multiple":false,"action":`https://api.viajar.tur.br/v1/media/upload-to-s3?module=contracts&id=${_vm.tempContract.id}&sub-folder=doc-back`,"remove":_vm.removeUploadedFile,"headers":{
                    authorization: `Bearer ${this.$store.state.token}`,
                  }},on:{"change":_vm.uploadedFile},model:{value:(_vm.tempContract['contract_files_doc_back']),callback:function ($$v) {_vm.$set(_vm.tempContract, 'contract_files_doc_back', $$v)},expression:"tempContract['contract_files_doc_back']"}},[(_vm.tempContract['contract_files_doc_back'] == '')?_c('a-button',{attrs:{"type":"dashed","shape":"circle","size":"large"}},[_c('a-icon',{attrs:{"type":"idcard"}})],1):_vm._e()],1)],1):_vm._e(),_vm._v(" "),(_vm.tempContract['contract_files_doc_back'])?_c('div',{staticClass:"file-link relative"},[(_vm.tempContract['contract_files_doc_back'])?_c('a',{attrs:{"href":_vm.tempContract['contract_files_doc_back'],"target":"_blank"}},[(
                      _vm.checkFileType(_vm.tempContract['contract_files_doc_back'])
                    )?_c('img',{staticStyle:{"max-width":"260px"},attrs:{"src":_vm.tempContract['contract_files_doc_back']}}):_c('a-icon',{staticClass:"cblue",staticStyle:{"font-size":"38px"},attrs:{"type":"idcard"}})],1):_vm._e(),_vm._v(" "),(_vm.tempContract['contract_files_doc_back'])?_c('a-icon',{staticClass:"close-circle",attrs:{"type":"close-circle"},on:{"click":function($event){return _vm.removefile('doc-back')}}}):_vm._e()],1):_vm._e()]),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',[_c('div',{staticClass:"label"},[_vm._v("Data de criação")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
                    'contract_files_doc_back_created',
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'contract_files_doc_back_created',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":"Selecione","format":"DD/MM/YYYY HH:mm","showToday":false,"allowClear":false,"disabled":true}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',[_c('div',{staticClass:"label"},[_vm._v("Data de validade")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
                    'contract_files_doc_back_expires_at',
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'contract_files_doc_back_expires_at',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":"Selecione","format":"DD/MM/YYYY","showToday":false,"allowClear":true,"disabled-date":_vm.minDate}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1)],1)],1)],2),_vm._v(" "),_c('a-tab-pane',{key:"2",attrs:{"force-render":""}},[_c('template',{slot:"tab"},[_c('a-icon',{staticClass:"f20",attrs:{"type":"credit-card"}})],1),_vm._v(" "),_c('a-row',{attrs:{"id":"contract-files","gutter":20}},[_c('a-col',{attrs:{"span":24}},[_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{staticClass:"mb-20",attrs:{"span":24}},[_c('div',{staticClass:"label mb-10"},[_vm._v("Frente do Cartão")]),_vm._v(" "),(!_vm.tempContract['contract_files_credit_card_front'])?_c('div',[_c('a-upload',{attrs:{"name":"file","multiple":false,"action":`https://api.viajar.tur.br/v1/media/upload-to-s3?module=contracts&id=${_vm.tempContract.id}&sub-folder=credit-card-front`,"remove":_vm.removeUploadedFile,"headers":{
                    authorization: `Bearer ${this.$store.state.token}`,
                  }},on:{"change":_vm.uploadedFile},model:{value:(_vm.tempContract['contract_files_credit_card_front']),callback:function ($$v) {_vm.$set(_vm.tempContract, 'contract_files_credit_card_front', $$v)},expression:"tempContract['contract_files_credit_card_front']"}},[(
                      _vm.tempContract['contract_files_credit_card_front'] == ''
                    )?_c('a-button',{attrs:{"type":"dashed","shape":"circle","size":"large"}},[_c('a-icon',{attrs:{"type":"credit-card"}})],1):_vm._e()],1)],1):_vm._e(),_vm._v(" "),(_vm.tempContract['contract_files_credit_card_front'])?_c('div',{staticClass:"file-link relative"},[(_vm.tempContract['contract_files_credit_card_front'])?_c('a',{attrs:{"href":_vm.tempContract['contract_files_credit_card_front'],"target":"_blank"}},[(
                      _vm.checkFileType(
                        _vm.tempContract['contract_files_credit_card_front']
                      )
                    )?_c('img',{staticStyle:{"max-width":"260px"},attrs:{"src":_vm.tempContract['contract_files_credit_card_front']}}):_c('a-icon',{staticClass:"cblue",staticStyle:{"font-size":"38px"},attrs:{"type":"credit-card"}})],1):_vm._e(),_vm._v(" "),(_vm.tempContract['contract_files_credit_card_front'])?_c('a-icon',{staticClass:"close-circle",attrs:{"type":"close-circle"},on:{"click":function($event){return _vm.removefile('credit-card-front')}}}):_vm._e()],1):_vm._e()]),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',[_c('div',{staticClass:"label"},[_vm._v("Data de criação")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
                    'contract_files_credit_card_front_created',
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'contract_files_credit_card_front_created',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":"Selecione","format":"DD/MM/YYYY HH:mm","showToday":false,"allowClear":false,"disabled":true}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',[_c('div',{staticClass:"label"},[_vm._v("Data de validade")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
                    'contract_files_credit_card_front_expires_at',
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'contract_files_credit_card_front_expires_at',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":"Selecione","format":"DD/MM/YYYY","showToday":false,"allowClear":true,"disabled-date":_vm.minDate}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":24}},[_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{staticClass:"mb-20",attrs:{"span":24}},[_c('div',{staticClass:"label mb-10"},[_vm._v("Verso do Cartão")]),_vm._v(" "),(!_vm.tempContract['contract_files_credit_card_back'])?_c('div',[_c('a-upload',{attrs:{"multiple":false,"action":`https://api.viajar.tur.br/v1/media/upload-to-s3?module=contracts&id=${_vm.tempContract.id}&sub-folder=credit-card-back`,"remove":_vm.removeUploadedFile,"headers":{
                    authorization: `Bearer ${this.$store.state.token}`,
                  }},on:{"change":_vm.uploadedFile},model:{value:(_vm.tempContract['contract_files_credit_card_back']),callback:function ($$v) {_vm.$set(_vm.tempContract, 'contract_files_credit_card_back', $$v)},expression:"tempContract['contract_files_credit_card_back']"}},[(
                      _vm.tempContract['contract_files_credit_card_back'] == ''
                    )?_c('a-button',{attrs:{"type":"dashed","shape":"circle","size":"large"}},[_c('a-icon',{attrs:{"type":"credit-card"}})],1):_vm._e()],1)],1):_vm._e(),_vm._v(" "),(_vm.tempContract['contract_files_credit_card_back'])?_c('div',{staticClass:"file-link relative"},[(_vm.tempContract['contract_files_credit_card_back'])?_c('a',{attrs:{"href":_vm.tempContract['contract_files_credit_card_back'],"target":"_blank"}},[(
                      _vm.checkFileType(
                        _vm.tempContract['contract_files_credit_card_back']
                      )
                    )?_c('img',{staticStyle:{"max-width":"260px"},attrs:{"src":_vm.tempContract['contract_files_credit_card_back']}}):_c('a-icon',{staticClass:"cblue",staticStyle:{"font-size":"38px"},attrs:{"type":"credit-card"}})],1):_vm._e(),_vm._v(" "),(_vm.tempContract['contract_files_credit_card_back'])?_c('a-icon',{staticClass:"close-circle",attrs:{"type":"close-circle"},on:{"click":function($event){return _vm.removefile('credit-card-back')}}}):_vm._e()],1):_vm._e()]),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',[_c('div',{staticClass:"label"},[_vm._v("Data de criação")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
                    'contract_files_credit_card_back_created',
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'contract_files_credit_card_back_created',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":"Selecione","format":"DD/MM/YYYY HH:mm","showToday":false,"allowClear":false,"disabled":true}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',[_c('div',{staticClass:"label"},[_vm._v("Data de validade")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
                    'contract_files_credit_card_back_expires_at',
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'contract_files_credit_card_back_expires_at',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":"Selecione","format":"DD/MM/YYYY","showToday":false,"allowClear":true,"disabled-date":_vm.minDate}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":24}},[_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{staticClass:"mb-20",attrs:{"span":24}},[_c('div',{staticClass:"label mb-10"},[_vm._v("Slip de pagamento")]),_vm._v(" "),(!_vm.tempContract['contract_files_credit_card_slip'])?_c('div',[_c('a-upload',{attrs:{"multiple":false,"action":`https://api.viajar.tur.br/v1/media/upload-to-s3?module=contracts&id=${_vm.tempContract.id}&sub-folder=credit-card-slip`,"remove":_vm.removeUploadedFile,"headers":{
                    authorization: `Bearer ${this.$store.state.token}`,
                  }},on:{"change":_vm.uploadedFile},model:{value:(_vm.tempContract['contract_files_credit_card_slip']),callback:function ($$v) {_vm.$set(_vm.tempContract, 'contract_files_credit_card_slip', $$v)},expression:"tempContract['contract_files_credit_card_slip']"}},[(
                      _vm.tempContract['contract_files_credit_card_slip'] == ''
                    )?_c('a-button',{attrs:{"type":"dashed","shape":"circle","size":"large"}},[_c('a-icon',{attrs:{"type":"profile"}})],1):_vm._e()],1)],1):_vm._e(),_vm._v(" "),(_vm.tempContract['contract_files_credit_card_slip'])?_c('div',{staticClass:"file-link relative"},[(_vm.tempContract['contract_files_credit_card_slip'])?_c('a',{attrs:{"href":_vm.tempContract['contract_files_credit_card_slip'],"target":"_blank"}},[(
                      _vm.checkFileType(
                        _vm.tempContract['contract_files_credit_card_slip']
                      )
                    )?_c('img',{staticStyle:{"max-width":"260px"},attrs:{"src":_vm.tempContract['contract_files_credit_card_slip']}}):_c('a-icon',{staticClass:"cblue",staticStyle:{"font-size":"38px"},attrs:{"type":"profile"}})],1):_vm._e(),_vm._v(" "),(_vm.tempContract['contract_files_credit_card_slip'])?_c('a-icon',{staticClass:"close-circle",attrs:{"type":"close-circle"},on:{"click":function($event){return _vm.removefile('credit-card-slip')}}}):_vm._e()],1):_vm._e()]),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',[_c('div',{staticClass:"label"},[_vm._v("Data de criação")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
                    'contract_files_credit_card_slip_created',
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'contract_files_credit_card_slip_created',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":"Selecione","format":"DD/MM/YYYY HH:mm","showToday":false,"allowClear":false,"disabled":true}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1)],1)],1)],2),_vm._v(" "),_c('a-tab-pane',{key:"3",attrs:{"force-render":""}},[_c('template',{slot:"tab"},[_c('a-icon',{staticClass:"f20",attrs:{"type":"file-protect"}})],1),_vm._v(" "),_c('a-row',{attrs:{"id":"contract-files","gutter":20}},[_c('a-col',{attrs:{"span":24}},[_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{staticClass:"mb-20",attrs:{"span":24}},[_c('div',{staticClass:"label mb-10"},[_vm._v("Contrato Assinado")]),_vm._v(" "),(!_vm.tempContract['contract_files_signed_contract'])?_c('div',[_c('a-upload',{attrs:{"multiple":false,"action":`https://api.viajar.tur.br/v1/media/upload-to-s3?module=contracts&id=${_vm.tempContract.id}&sub-folder=signed-contract`,"remove":_vm.removeUploadedFile,"headers":{
                    authorization: `Bearer ${this.$store.state.token}`,
                  }},on:{"change":_vm.uploadedFile},model:{value:(_vm.tempContract['contract_files_signed_contract']),callback:function ($$v) {_vm.$set(_vm.tempContract, 'contract_files_signed_contract', $$v)},expression:"tempContract['contract_files_signed_contract']"}},[(
                      _vm.tempContract['contract_files_signed_contract'] == ''
                    )?_c('a-button',{attrs:{"type":"dashed","shape":"circle","size":"large"}},[_c('a-icon',{attrs:{"type":"file-protect"}})],1):_vm._e()],1)],1):_vm._e(),_vm._v(" "),(_vm.tempContract['contract_files_signed_contract'])?_c('div',{staticClass:"file-link relative"},[(_vm.tempContract['contract_files_signed_contract'])?_c('a',{attrs:{"href":_vm.tempContract['contract_files_signed_contract'],"target":"_blank"}},[(
                      _vm.checkFileType(
                        _vm.tempContract['contract_files_signed_contract']
                      )
                    )?_c('img',{staticStyle:{"max-width":"260px"},attrs:{"src":_vm.tempContract['contract_files_signed_contract']}}):_c('a-icon',{staticClass:"cblue",staticStyle:{"font-size":"38px"},attrs:{"type":"file-protect"}})],1):_vm._e(),_vm._v(" "),(_vm.tempContract['contract_files_signed_contract'])?_c('a-icon',{staticClass:"close-circle",attrs:{"type":"close-circle"},on:{"click":function($event){return _vm.removefile('signed-contract')}}}):_vm._e()],1):_vm._e()]),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',[_c('div',{staticClass:"label"},[_vm._v("Data de criação")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
                    'contract_files_signed_contract_created',
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'contract_files_signed_contract_created',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":"Selecione","format":"DD/MM/YYYY HH:mm","showToday":false,"allowClear":false,"disabled":true}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',[_c('div',{staticClass:"label"},[_vm._v("Data de validade")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
                    'contract_files_signed_contract_expires_at',
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'contract_files_signed_contract_expires_at',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":"Selecione","format":"DD/MM/YYYY","showToday":false,"allowClear":true,"disabled-date":_vm.minDate}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1)],1)],1)],2),_vm._v(" "),_c('a-tab-pane',{key:"4",attrs:{"force-render":""}},[_c('template',{slot:"tab"},[_c('a-icon',{staticClass:"f20",attrs:{"type":"folder-open"}})],1),_vm._v(" "),_c('a-row',{attrs:{"id":"contract-files","gutter":20}},[_c('a-col',{attrs:{"span":24}},[_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{staticClass:"mb-20",attrs:{"span":24}},[_c('div',{staticClass:"label mb-10"},[_vm._v("Outros arquivos")]),_vm._v(" "),(!_vm.tempContract['contract_files_other_files'])?_c('div',[_c('a-upload',{attrs:{"multiple":false,"action":`https://api.viajar.tur.br/v1/media/upload-to-s3?module=contracts&id=${_vm.tempContract.id}&sub-folder=other-files`,"remove":_vm.removeUploadedFile,"headers":{
                    authorization: `Bearer ${this.$store.state.token}`,
                  }},on:{"change":_vm.uploadedFile},model:{value:(_vm.tempContract['contract_files_other_files']),callback:function ($$v) {_vm.$set(_vm.tempContract, 'contract_files_other_files', $$v)},expression:"tempContract['contract_files_other_files']"}},[(_vm.tempContract['contract_files_other_files'] == '')?_c('a-button',{attrs:{"type":"dashed","shape":"circle","size":"large"}},[_c('a-icon',{attrs:{"type":"folder"}})],1):_vm._e()],1)],1):_vm._e(),_vm._v(" "),(_vm.tempContract['contract_files_other_files'])?_c('div',{staticClass:"file-link relative"},[(_vm.tempContract['contract_files_other_files'])?_c('a',{attrs:{"href":_vm.tempContract['contract_files_other_files'],"target":"_blank"}},[(
                      _vm.checkFileType(
                        _vm.tempContract['contract_files_other_files']
                      )
                    )?_c('img',{staticStyle:{"max-width":"260px"},attrs:{"src":_vm.tempContract['contract_files_other_files']}}):_c('a-icon',{staticClass:"cblue",staticStyle:{"font-size":"38px"},attrs:{"type":"folder"}})],1):_vm._e(),_vm._v(" "),(_vm.tempContract['contract_files_other_files'])?_c('a-icon',{staticClass:"close-circle",attrs:{"type":"close-circle"},on:{"click":function($event){return _vm.removefile('other-files')}}}):_vm._e()],1):_vm._e()]),_vm._v(" "),_c('a-col',{staticClass:"none",attrs:{"span":12}},[_c('a-form-item',[_c('div',{staticClass:"label"},[_vm._v("Data de criação")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
                    'contract_files_other_files_created',
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'contract_files_other_files_created',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":"Selecione","format":"DD/MM/YYYY HH:mm","showToday":false,"allowClear":false,"disabled":true}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1)],1)],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }